<template>
  <card
    ref="card"
    :loading="loading"
    :title="$tc('app.plan', 2)"
    actions
    block
    @reload="fetch"
  >
    <template #body>
      <router-link
        :to="{ name: 'AdminPlansCreate' }"
        class="btn btn-primary m-b-5"
      >
        <i
          class="fas fa-plus-circle text-white font-size-22 v-align-text-bottom"
        ></i>
      </router-link>
      <data-table
        :columns="[
          {
            key: 'service',
            class: 'badge badge-primary',
            label: 'type',
          },
          'name',
        ]"
        :data="plans"
        :pages="pages"
        @change-page="fetch"
      >
        <template #actions="item">
          <dropdown>
            <template v-slot:text>
              <i class="fas fa-ellipsis-v"></i>
            </template>
            <template v-slot:items>
              <router-link
                :to="{ name: 'AdminPlanEdit', params: { planId: item.id } }"
                class="dropdown-item"
              >{{ $t('app.edit') }}
              </router-link
              >
              <a
                class="dropdown-item"
                href="javascript:void(0)"
                @click="destroy(item.id)"
              >
                {{ $t('app.delete') }}
              </a>
            </template>
          </dropdown>
        </template>
      </data-table>
      <modal
        ref="plan-modal"
        :title="$t('generic-str.new-plan')"
        @submit="store"
      >
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">
            {{ $t('name') }}
          </label>
          <div class="col-sm-10">
            <input v-model="form.name" class="form-control" type="text"/>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">
            {{ $t('type') }}
          </label>
          <div class="col-sm-10">
            <select v-model="form.service" class="form-control" required>
              <option selected value="sms">SMS</option>
              <!-- <option value="omni">Omni Business</option> -->
              <option value="omni">Chat</option>
              <option value="whatsapp">Whatsapp API</option>
              <option value="rcs">Google RCS</option>
              <option value="email">E-Mail</option>
              <option value="lookup">Lookup</option>
            </select>
          </div>
        </div>
      </modal>
    </template>
  </card>
</template>

<script>
// @ is an alias to /src
import Card from '@/components/Card.vue';
import DataTable from '@/components/DataTable.vue';
import Dropdown from '@/components/Dropdown.vue';
import Modal from '@/components/Modal.vue';

export default {
  name: 'AdminPlansCard',
  components: {
    Card,
    DataTable,
    Dropdown,
    Modal,
  },
  data() {
    return {
      loading: true,
      plans: [],
      page: 1,
      pages: 1,
      form: {},
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch(page = this.page) {
      this.page = page;
      this.loading = true;
      this.$store.dispatch('fetchAdminPlans', { page }).then((response) => {
        this.plans = response.data;
        this.pages = response.last_page;
        this.loading = false;
      });
    },
    store() {
      this.loading = true;
      this.$store.dispatch('storeAdminPlans', this.form).then((response) => {
        this.fetch();
      });
    },
    update(planId) {
      this.loading = true;
      this.$store
        .dispatch('updateAdminPlans', {
          planId,
          input: this.form,
        })
        .then((response) => {
          this.fetch();
        });
    },
    destroy(planId) {
      this.loading = true;
      this.$store.dispatch('destroyAdminPlan', planId).then((response) => {
        this.fetch();
      });
    },
  },
};
</script>
