<template>
  <div class="content">
    <PageHeader :title="$tc('app.plan', 2)" />
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <admin-plans-card ref="plans" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import AdminPlansCard from '@/components/cards/AdminPlansCard.vue';

export default {
  name: 'PlansIndex',
  components: {
    AdminPlansCard,
    PageHeader,
  },
  data() {
    return {
      fetched: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 4,
        masked: false,
      },
      plans: null,
      form: {
        page: 1,
      },
      lastPage: 1,
      pages: 1,
      isSending: false,
    };
  },
};
</script>
<style scoped lang="scss">
.badge {
  text-transform: uppercase;
}
table {
  thead {
    text-transform: uppercase;
  }
  tbody {
    tr:hover {
      background-color: #f0f6ff;
    }
  }
}
</style>
